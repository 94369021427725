import React, { useCallback, useState } from 'react'

import { Link, useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'
import HeadShotEmoji from '../components/headshotEmoji'
//@ts-ignore
import { ReactComponent as WaveEmoji } from '../static/assets/1F44B.svg'
import { useEffect } from 'react'

const containerCss = css`
  font-size: 2.5em;
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 50% auto;
  margin: 1em;
  margin-top: 2em;
  @media (max-aspect-ratio: 1/1) {
    display: block;
  }
`

const greetingCss = css`
  display: flex;
  align-items: center;
`

const IndexPage = () => {
  const [isPortrait, setIsPortrait] = useState(false)

  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "resume_redacted" }) {
        publicURL
      }
    }
  `)

  const handleResize = useCallback(() => {
    setIsPortrait(window.innerWidth / window.innerHeight < 1)
  }, [])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <div css={containerCss}>
      {isPortrait && <HeadShotEmoji />}
      <div
        css={css`
          margin-bottom: 1em;
        `}
      >
        <div css={greetingCss}>
          <span>Hello</span>
          <WaveEmoji height={'1.5em'} width={'1.5em'} />,
        </div>
        <div>
          <div>
            My name is Christian. I'm a Software Engineer currently working at
            Prism Data in NYC. If you're a Very Serious Person™ you can take a
            look at my{' '}
            <Link
              css={css`
                font-weight: 500;
                cursor: pointer;
                text-decoration: underline;
                color: inherit;
                :hover {
                  background-color: rgba(var(--border-bg-color), 0.5);
                }
              `}
              to={data?.file?.publicURL ?? ''}
            >
              experience
            </Link>
            . Otherwise, {isPortrait ? 'above' : 'to the right'} you will find a
            picture of me smiling. If you hover, you can watch me experience the
            full range of human emotion as expressible through :emojis:. At the
            top right corner of the page you will find 4 colorful squares;
            clicking them will change the theme of the website. I'd encourage
            you do so, if only for the dopamine hit
          </div>
          <div
            css={css`
              margin-top: 1em;
            `}
          >
            ××× Christian
          </div>
        </div>
      </div>
      {!isPortrait && <HeadShotEmoji />}
    </div>
  )
}

export default IndexPage
