//@ts-nocheck
import React, { useState } from 'react'
import { css } from '@emotion/react'

import Image from './image'
import { ReactComponent as Svg0 } from '../static/assets/E281.svg'
import { ReactComponent as Svg1 } from '../static/assets/1F643.svg'
import { ReactComponent as Svg2 } from '../static/assets/1F600.svg'
import { ReactComponent as Svg3 } from '../static/assets/1F601.svg'
import { ReactComponent as Svg4 } from '../static/assets/1F603.svg'
import { ReactComponent as Svg5 } from '../static/assets/1F604.svg'
import { ReactComponent as Svg6 } from '../static/assets/1F605.svg'
import { ReactComponent as Svg7 } from '../static/assets/1F606.svg'
import { ReactComponent as Svg8 } from '../static/assets/1F607.svg'
import { ReactComponent as Svg9 } from '../static/assets/1F609.svg'
import { ReactComponent as Svg10 } from '../static/assets/1F60A.svg'
import { ReactComponent as Svg11 } from '../static/assets/1F60B.svg'
import { ReactComponent as Svg12 } from '../static/assets/1F60C.svg'
import { ReactComponent as Svg13 } from '../static/assets/1F60D.svg'
import { ReactComponent as Svg14 } from '../static/assets/1F60E.svg'
import { ReactComponent as Svg15 } from '../static/assets/1F60F.svg'
import { ReactComponent as Svg16 } from '../static/assets/1F610.svg'
import { ReactComponent as Svg17 } from '../static/assets/1F611.svg'
import { ReactComponent as Svg18 } from '../static/assets/1F612.svg'
import { ReactComponent as Svg19 } from '../static/assets/1F613.svg'
import { ReactComponent as Svg20 } from '../static/assets/1F614.svg'
import { ReactComponent as Svg21 } from '../static/assets/1F615.svg'
import { ReactComponent as Svg22 } from '../static/assets/1F616.svg'
import { ReactComponent as Svg23 } from '../static/assets/1F617.svg'
import { ReactComponent as Svg24 } from '../static/assets/1F618.svg'
import { ReactComponent as Svg25 } from '../static/assets/1F619.svg'
import { ReactComponent as Svg26 } from '../static/assets/1F61A.svg'
import { ReactComponent as Svg27 } from '../static/assets/1F61B.svg'
import { ReactComponent as Svg28 } from '../static/assets/1F61C.svg'
import { ReactComponent as Svg29 } from '../static/assets/1F61D.svg'
import { ReactComponent as Svg30 } from '../static/assets/1F61E.svg'
import { ReactComponent as Svg31 } from '../static/assets/1F61F.svg'
import { ReactComponent as Svg32 } from '../static/assets/1F620.svg'
import { ReactComponent as Svg33 } from '../static/assets/1F622.svg'
import { ReactComponent as Svg34 } from '../static/assets/1F623.svg'
import { ReactComponent as Svg35 } from '../static/assets/1F624.svg'
import { ReactComponent as Svg36 } from '../static/assets/1F625.svg'
import { ReactComponent as Svg37 } from '../static/assets/1F626.svg'
import { ReactComponent as Svg38 } from '../static/assets/1F627.svg'
import { ReactComponent as Svg39 } from '../static/assets/1F628.svg'
import { ReactComponent as Svg40 } from '../static/assets/1F629.svg'
import { ReactComponent as Svg41 } from '../static/assets/1F62A.svg'
import { ReactComponent as Svg42 } from '../static/assets/1F62B.svg'
import { ReactComponent as Svg43 } from '../static/assets/1F62C.svg'
import { ReactComponent as Svg44 } from '../static/assets/1F62D.svg'
import { ReactComponent as Svg45 } from '../static/assets/1F62E.svg'
import { ReactComponent as Svg46 } from '../static/assets/1F62F.svg'
import { ReactComponent as Svg47 } from '../static/assets/1F630.svg'
import { ReactComponent as Svg48 } from '../static/assets/1F632.svg'
import { ReactComponent as Svg49 } from '../static/assets/1F633.svg'
import { ReactComponent as Svg50 } from '../static/assets/1F634.svg'
import { ReactComponent as Svg51 } from '../static/assets/1F636.svg'
import { ReactComponent as Svg52 } from '../static/assets/1F641.svg'
import { ReactComponent as Svg53 } from '../static/assets/1F642.svg'
import { ReactComponent as Svg54 } from '../static/assets/1F644.svg'
import { ReactComponent as Svg55 } from '../static/assets/1F914.svg'
import { ReactComponent as Svg56 } from '../static/assets/1F917.svg'
import { ReactComponent as Svg57 } from '../static/assets/1F923.svg'
import { ReactComponent as Svg58 } from '../static/assets/1F924.svg'
import { ReactComponent as Svg59 } from '../static/assets/1F928.svg'
import { ReactComponent as Svg60 } from '../static/assets/1F929.svg'
import { ReactComponent as Svg61 } from '../static/assets/1F92A.svg'
import { ReactComponent as Svg62 } from '../static/assets/1F92D.svg'
import { ReactComponent as Svg63 } from '../static/assets/1F92F.svg'
import { ReactComponent as Svg64 } from '../static/assets/1F970.svg'
import { ReactComponent as Svg65 } from '../static/assets/1F972.svg'
import { ReactComponent as Svg66 } from '../static/assets/1F973.svg'
import { ReactComponent as Svg67 } from '../static/assets/1F974.svg'
import { ReactComponent as Svg68 } from '../static/assets/1F975.svg'
import { ReactComponent as Svg69 } from '../static/assets/1F97A.svg'
import { ReactComponent as Svg70 } from '../static/assets/1F9D0.svg'
import { ReactComponent as Svg71 } from '../static/assets/2639.svg'
import { ReactComponent as Svg72 } from '../static/assets/263A.svg'
import { ReactComponent as Svg73 } from '../static/assets/E280.svg'

const Svgs = [
  Svg0,
  Svg1,
  Svg2,
  Svg3,
  Svg4,
  Svg5,
  Svg6,
  Svg7,
  Svg8,
  Svg9,
  Svg10,
  Svg11,
  Svg12,
  Svg13,
  Svg14,
  Svg15,
  Svg16,
  Svg17,
  Svg18,
  Svg19,
  Svg20,
  Svg21,
  Svg22,
  Svg23,
  Svg24,
  Svg25,
  Svg26,
  Svg27,
  Svg28,
  Svg29,
  Svg30,
  Svg31,
  Svg32,
  Svg33,
  Svg34,
  Svg35,
  Svg36,
  Svg37,
  Svg38,
  Svg39,
  Svg40,
  Svg41,
  Svg42,
  Svg43,
  Svg44,
  Svg45,
  Svg46,
  Svg47,
  Svg48,
  Svg49,
  Svg50,
  Svg51,
  Svg52,
  Svg53,
  Svg54,
  Svg55,
  Svg56,
  Svg57,
  Svg58,
  Svg59,
  Svg60,
  Svg61,
  Svg62,
  Svg63,
  Svg64,
  Svg65,
  Svg66,
  Svg67,
  Svg68,
  Svg69,
  Svg70,
  Svg71,
  Svg72,
  Svg73,
]

const HeadShotEmoji: React.FC<{}> = ({}) => {
  const [isHoveredAndIndex, setIsHoveredAndIndex] = useState<{
    hovered: boolean
    index: number
  }>({
    hovered: false,
    index: 0,
  })

  const Svg = Svgs[isHoveredAndIndex.index]

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      `}
    >
      <div
        css={css`
          position: absolute;
          width: 10em;
          height: 10em;
          z-index: 2;
          overflow: hidden;
        `}
        onMouseOver={() =>
          setIsHoveredAndIndex({
            hovered: true,
            index: Math.floor(Math.random() * 73),
          })
        }
        onMouseOut={() =>
          setIsHoveredAndIndex({ ...isHoveredAndIndex, hovered: false })
        }
        onTouchStart={() =>
          setIsHoveredAndIndex({
            hovered: true,
            index: Math.floor(Math.random() * 73),
          })
        }
        onTouchEnd={() =>
          setIsHoveredAndIndex({ ...isHoveredAndIndex, hovered: false })
        }
      />

      <Svg
        css={css`
          position: absolute;
          z-index: 1;
          display: ${isHoveredAndIndex.hovered ? 'block' : 'none'};
        `}
        height={'10.5em'}
        width={'10.5em'}
      />
      <Image />
    </div>
  )
}

export default HeadShotEmoji
